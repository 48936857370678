import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { silentAuth } from './src/utils/auth';
import { ApolloWrapper } from './src/apollo/ApolloWrapper';
import { SiteContextProvider } from './src/SiteContext';
import * as Sentry from '@sentry/browser';
import { Helmet } from 'react-helmet';

Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
    release: process.env.SENTRY_RELEASE,
});
class SessionCheck extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    handleCheckSession = () => {
        this.setState({ loading: false });
    };

    componentDidMount() {
        silentAuth(this.handleCheckSession);
    }

    render() {
        return (
            this.state.loading === false && (
                <React.Fragment>{this.props.children}</React.Fragment>
            )
        );
    }
}

const theme = createMuiTheme();
const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY;
export const wrapRootElement = ({ element }) => {
    return (
        <SessionCheck>
            <CssBaseline>
                <ApolloWrapper>
                    <SiteContextProvider>
                        <Helmet>
                            {
                                <script
                                    id='google-maps'
                                    src={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`}
                                ></script>
                            }
                        </Helmet>
                        <ThemeProvider theme={theme}>{element}</ThemeProvider>
                    </SiteContextProvider>
                </ApolloWrapper>
            </CssBaseline>
        </SessionCheck>
    );
};
