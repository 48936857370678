import auth0 from 'auth0-js';
import { navigate } from 'gatsby';

const isBrowser = typeof window !== 'undefined';

const auth = isBrowser
    ? new auth0.WebAuth({
          domain: process.env.AUTH0_DOMAIN,
          clientID: process.env.AUTH0_CLIENTID,
          redirectUri: process.env.AUTH0_CALLBACK,
          responseType: 'token id_token',
          scope: 'openid profile email',
      })
    : ({} as auth0.WebAuth);

const tokens = {
    accessToken: false,
    idToken: false,
    expiresAt: undefined,
};

let user = {};
let idToken = null;

export const isAuthenticated = () => {
    if (!isBrowser) {
        return;
    }

    return localStorage.getItem('isLoggedIn') === 'true';
};

export const login = (search) => {
    if (!isBrowser) {
        return;
    }

    auth.authorize({
        appState: `${window.location.pathname}${search ?? ''}`,
    });
};

const setSession = (cb = () => null) => (err, authResult) => {
    if (err) {
        localStorage.setItem('isLoggedIn', 'false');
        cb();
        return;
    }

    if (authResult && authResult.accessToken && authResult.idToken) {
        const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
        tokens.accessToken = authResult.accessToken;
        tokens.idToken = authResult.idToken;
        tokens.expiresAt = expiresAt;
        idToken = authResult.idToken;
        user = authResult.idTokenPayload;
        localStorage.setItem('isLoggedIn', 'true');
        const redirect = authResult.appState || '/';
        navigate(redirect);
        cb();
    }
};

export const silentAuth = (callback) => {
    if (!isAuthenticated()) return callback();
    auth.checkSession(
        {
            state: window.location.pathname + window.location.search,
        },
        setSession(callback)
    );
};

export const handleAuthentication = () => {
    if (!isBrowser) {
        return;
    }

    auth.parseHash(setSession());
};

export const getProfile = () => {
    return user;
};

export const getIdToken = () => {
    return idToken;
};

export const logout = () => {
    tokens.accessToken = false;
    tokens.idToken = false;
    user = {};
    localStorage.setItem('isLoggedIn', '');
    auth.logout({});
};
