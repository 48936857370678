import React, { createContext, useContext, useState } from 'react';

interface ISiteContext {
    siteId: string;
    setSiteId: React.Dispatch<React.SetStateAction<string>>;
}
const SiteContext = createContext<ISiteContext | null>(null);

export const useSiteContext = () => {
    const context = useContext(SiteContext);

    if (!context) {
        throw new Error('Site context not mounted');
    }
    return context;
};

export const SiteContextProvider = ({ children }) => {
    const [siteId, setSiteId] = useState();
    return (
        <SiteContext.Provider value={{ setSiteId, siteId }}>
            {children}
        </SiteContext.Provider>
    );
};
